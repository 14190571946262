




























import {Component, Vue} from 'vue-property-decorator';
import Loader from '@/components/Loader.vue';
import {vxm} from '@/store';
import {ConfirmPageSettingsInterface} from '@/types/ConfirmPageSettingsInterface';

@Component({components: {Loader}})
export default class ConfirmPage extends Vue {
  isConfirm = false;
  isError = false;
  isRequestSend = true;
  pageSettingsList = {
    confirmEmail: {
      title: this.$t('fan-confirmed-email') as string,
      buttonTitle: this.$t('login') as string,
      buttonLink: '/login',
      apiName: 'confirmEmail',
    },
    unsubscribeEmail: {
      title: 'You have been successfully unsubscribed from Sales and Marketing emails',
      buttonTitle: this.$t('to-home') as string,
      buttonLink: '/',
    },
  };
  pageSettings: ConfirmPageSettingsInterface | null = null;

  mounted() {
    this.setPageSetting();
    localStorage.setItem('agree', 'true');
    if (this.pageSettings?.apiName) {
      vxm.user[this.pageSettings.apiName](this.$route.query.token)
        .then(() => {
          this.isConfirm = true;
        })
        .catch(this.errorNotify)
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }

  setPageSetting() {
    if (this.$route.name === 'confirm-email') {
      this.pageSettings = this.pageSettingsList.confirmEmail;
      return;
    }

    if (this.$route.name === 'unsubscribe-email') {
      this.pageSettings = this.pageSettingsList.unsubscribeEmail;
      this.isRequestSend = false;
      this.isConfirm = true;
      return;
    }

    this.isRequestSend = false;
    this.errorNotify();
  }

  errorNotify() {
    this.isError = true;
    this.$notify({
      group: 'foo',
      title: this.$t('error.wrong-confirm-link') as string,
      type: 'error',
      duration: 5000,
    });
  }
}
